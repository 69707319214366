export const AppRoutes = {
  home: "/",
  // team: "/team",
  privacypolicy: "/privacypolicy",
  corporate: "/Corporate",
  nri: "/Nri",
  // ondc: "/ONDC",
  about: "/About",
  contact: "/Contact",
  blog: "/Blog",
  bhandarakarwao: "/bhandarakarwao",
};
