import { clarity as clarityInit } from "./clarity";

const __clarity = (...args) => {
  if (!window.clarity) {
    throw new Error("Clarity is not initialized");
  }

  window.clarity.apply(undefined, args);
};

const init = (id) => {
  clarityInit(id);
};

const hasStarted = () => {
  return typeof window.clarity === "function";
};

const identify = (userId, properties) => {
  __clarity("identify", userId, properties);
};

const consent = () => {
  __clarity("consent");
};

const setTag = (key, value) => {
  __clarity("set", key, value);
};

const upgrade = (reason) => {
  __clarity("upgrade", reason);
};

export const clarity = {
  init,
  hasStarted,
  identify,
  consent,
  setTag,
  upgrade,
};
