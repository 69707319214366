import React from "react";
import { Zoom } from "react-awesome-reveal";

// const CounterComponent = ({ id, start, end, duration }) => {
//   const [currentCount, setCurrentCount] = useState(start);

//   useEffect(() => {
//     let increment = end > start ? 1 : -1;
//     let range = Math.abs(end - start);
//     let step = Math.abs(Math.floor(duration / range));

//     const timer = setInterval(() => {
//       setCurrentCount((prevCount) => prevCount + increment);
//     }, step);

//     return () => clearInterval(timer);
//   }, [start, end, duration]);

//   return <span id={id}>{currentCount}</span>;
// };

const CounterSection = () => {
  return (
    <Zoom triggerOnce={true}>
      <section className="counter-wrapper">
        <div className="counter-inner">
          <div className="container">
            <div className="row g-0">
              <div className="col-6 col-lg-3">
                <div className="py-5 text-center text-white text-white">
                  <div>
                    <i className="bi bi-building count-icon"></i>
                  </div>
                  <div className="py-2 count">
                    <span id="count1">50</span>+
                  </div>
                  <div>
                    <h5 className="text-center text-white">
                      {" "}
                      <b>Daily Bhandara Post</b>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="py-5 text-center text-white text-white">
                  <div>
                    <i className="bi bi-people count-icon"></i>
                  </div>
                  <div className="py-2 count">
                    <span id="count2">500</span>K+
                  </div>
                  <div>
                    <h5 className="text-center text-white">
                      {" "}
                      <b> Downloads</b>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="py-5 text-center text-white text-white">
                  <div>
                    <i className="bi bi-trophy count-icon"></i>
                  </div>
                  <div className="py-2 count">
                    <span id="count3">15</span>+
                  </div>
                  <div>
                    <h5 className="text-center text-white">
                      {" "}
                      <b> Media Articles</b>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="py-5 text-center text-white text-white">
                  <div>
                    <i className="bi bi-graph-up count-icon"></i>
                  </div>
                  <div className="py-2 count">
                    <span id="count4">110</span>M+
                  </div>
                  <div>
                    <h5 className="text-center text-white">
                      {" "}
                      <b> Social Media Views</b>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Zoom>
  );
};

export default CounterSection;
