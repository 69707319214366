import React from "react";
import { Helmet } from "react-helmet";

export const SeoComponentsBuilder = ({ metaTags }) => {
  return (
    <Helmet>
      {metaTags.tags.map((tag, index) => {
        if (tag.title) {
          return <title key={index + 1}>{tag.title}</title>;
        }
        return null;
      })}
      {metaTags.meta.map((meta, index) => {
        return <meta key={index + 1} {...meta} />;
      })}
    </Helmet>
  );
};
