import React, { Suspense } from "react";
import { lazy } from "react";
const BhandaraKarwao = lazy(() => import("./BhandaraKarwao"));

const LoadingComponent = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100vw",
      top: 0,
      left: 0,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    }}
  >
    <div className="spinner-border" style={{ color: "#f16d08" }} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const BhandaraKarwaoWrapper = () => {
  return (
    <Suspense fallback={<LoadingComponent />}>
      <BhandaraKarwao />
    </Suspense>
  );
};

export default BhandaraKarwaoWrapper;
