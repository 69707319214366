import React from "react";
// import { Link } from "react-router-dom";
import "../style/footer.css";
function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-5 col-md-12 footer-info">
            <a href="/">
              {" "}
              <img className="blogo" src="/images/blogo.png" alt="" />
            </a>
            <h5>
              {" "}
              <b>India's 1st spritual food app.</b>
            </h5>
            <div className="social-links d-flex mt-8">
              <a
                href="https://twitter.com/bhandaara?t=aUBMr7O2TT_aiqXPkdZsWQ&s=08"
                className="twitter"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-x-twitter"></i>
              </a>
              <a
                href="https://www.facebook.com/bhandaraapp?mibextid=ZbWKwL"
                className="facebook"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/bhandara.app/?igsh=aDRhejJ1OHdxM2t0"
                className="instagram"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/bhandara/"
                className="linkedin"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
            <div className="mt-8">
              <a
                href="https://play.google.com/store/apps/details?id=com.bhandara&hl=en_US"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Google Play Store"
                  src="/images/playstore.png"
                  className="h-10 w-40 rounded-lg"
                />
              </a>
            </div>
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Quick Links</h4>
            <ul className="mt-3">
              <li>
                <a href="/Blog">Blogs</a>
              </li>

              <li>
                <a href="/About">About us</a>
              </li>
              <li>
                <a href="/Contact">Contact us</a>
              </li>
              {/* <li>
                <a href="/team">Our Team</a>
              </li> */}
              <li>
                <a href="/privacypolicy">Privacy policy</a>
              </li>
              <li>
                {/* <a
                  href="https://drive.google.com/drive/folders/1fNgPq7tqF5MgCVqoxtKT6wU0uU1o6AcZ"
                  target="_blank"
                  rel="noreferrer"
                >
                  Press Kit
                </a> */}
              </li>
            </ul>
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Our Products</h4>
            <ul className="mt-3">
              <li>
                <a href="/Corporate">Corporate Bookings</a>
              </li>
              <li>
                <a href="/Nri">Nri Portal</a>
              </li>
              {/* <li>
                <a href="/ONDC">ONDC</a>
              </li> */}
              <li>
                <a
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSe4qZG1_oOTRCMOXj9PyuqwIGtdRcHFTX6-ZyU78116jTbjFQ/viewform"
                  rel="noreferrer"
                >
                  Online Bandara karwao
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start ">
            <h4>Reach Us</h4>
            <p className="mt-2">
              <strong>AKHILA APPS PVT LTD</strong>
              <br />
              <span className="text-muted ">
                <small>CIN: U56291MP2024PTC072437</small>
              </span>
              <br />
              Registered Head Office:
              <br />
              <strong>
                <i className="fa-solid fa-location-dot"></i>
              </strong>
              {` Jadon Market,`}
              <br />
              Patali Hanuman Tansen Road, Hazira, Gwalior (MP)
              <br />
              474003 <br />
              <strong>
                <i className="fa-solid fa-phone"></i>
              </strong>{" "}
              +91 8595316628
              <br />
              <strong>
                <i className="fa-solid fa-envelope"></i>
              </strong>{" "}
              contact@bhandara.app
              <br />
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="copyright">
          <h4>
            {" "}
            &copy; Copyright{" "}
            <strong>
              <span>2024 Bhandara App</span>
            </strong>
            . All Rights Reserved{" "}
          </h4>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
