import React from "react";
// import { useNavigate } from "react-router-dom";
// import '../style/onlinebhandara.css'
import { Zoom } from "react-awesome-reveal";

const OnlineBhandara = () => {
  // const navigate = useNavigate();

  // const handleEnquireClick = () => {
  //   navigate('/enquire'); // Adjust the path as needed
  // };

  return (
    <Zoom triggerOnce={true}>
      <section id="Story" className="story2_section">
        <div className="container">
          <div className="row">
            <div className="story2_content">
              <h1 className="main_heading text-center">
                <b>Online Bhandara karwao</b>
              </h1>

              <div className="main_btn about_btn my-4 w-auto text-center">
                <button className="btn btn_all">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSe4qZG1_oOTRCMOXj9PyuqwIGtdRcHFTX6-ZyU78116jTbjFQ/viewform"
                  >
                    Book Now <i className="fa-solid fa-angle-right ms-1"></i>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Zoom>
  );
};

export default OnlineBhandara;
