import React from "react";
import { Zoom } from "react-awesome-reveal";

const BANNERS = [
  {
    id: 1,
    image: "/images/baner1.png",
    alt: "find free food",
  },
  {
    id: 2,
    image: "/images/karwao.png",
    alt: "bhandara karwao",
  },
  {
    id: 3,
    image: "/images/NRIPORTAL.png",
    alt: "nri portal",
  },
];

const CarouselExampleDark = () => {
  return (
    <Zoom triggerOnce={true} className="mt-[72px]">
      <div
        id="carouselExampleDark"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
        data-bs-interval="2800"
        data-bs-pause="false"
        data-bs-delay="0"
      >
        {/* <div className="carousel-indicators mt-100">
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div> */}
        <div className="carousel-inner">
          {BANNERS.map((banner) => (
            <div
              className={`carousel-item ${banner.id === 1 ? "active" : ""}`}
              style={{ animationDelay: "0s" }}
              key={banner.id}
            >
              <img
                src={banner.image}
                style={{ width: "100%" }}
                className="d-block w-100"
                alt={banner.alt}
              />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </Zoom>
  );
};

export default CarouselExampleDark;
