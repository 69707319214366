import React from "react";
import { Zoom } from "react-awesome-reveal";
import { SeoComponentsBuilder } from "../components/seoComponent/SeoComponentsBuilder";
import { metaTagsData } from "../routes/metaTagsData";
import { AppRoutes } from "../routes/routes";

function ContactUs() {
  return (
    <>
      <SeoComponentsBuilder metaTags={metaTagsData[AppRoutes.contact]} />
      <Zoom triggerOnce={true}>
        <br></br>
        <br></br>

        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <div className="app">
                <h1 className="text-center text-dark app mt-5">
                  <b>Get in touch</b>
                </h1>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-4" data-aos="fade-right">
                <div className="info">
                  <div className="address">
                    <i className="fa-solid fa-location-dot"></i>
                    <h4>Address:</h4>
                    <p>
                      Jadon Market, Patali Hanuman Tansen Road, Hazira, Gwalior
                      (MP) 474003
                    </p>
                  </div>

                  <div className="email">
                    <i className="fa-solid fa-envelope"></i>
                    <h4>Email:</h4>
                    <p>contact@bhandara.app</p>
                  </div>

                  <div className="phone">
                    <i className="fa-solid fa-phone"></i>
                    <h4>Call:</h4>
                    <p> +91 8595316628</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">
                <form
                  action="forms/contact.php"
                  method="post"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      {/* <img src='/getin.jpg' /> */}

                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">
                      <a
                        rel="noreferrer"
                        className="text-white"
                        target="_blank"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSefVVCUbG-IbwlXSVh6X5aFnsstbf6XGaHeb8qIPPyigTgQNQ/viewform"
                      >
                        Send Message
                      </a>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="map_sec">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="map_inner">
                  <h2 className="text-center text-dark">
                    <b>Find Us on Google Map</b>
                  </h2>
                  <div className="map_bind">
                    <iframe
                      title="bhandara map"
                      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3578.997749170067!2d78.17932307541608!3d26.229262677061175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDEzJzQ1LjQiTiA3OMKwMTAnNTQuOCJF!5e0!3m2!1sen!2sin!4v1716392164233!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      frameborder="0"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Zoom>
    </>
  );
}

export default ContactUs;
