// import logo from './images/favicon1.png';
// import logo from "./assets/favicon.jpg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
// import TeamPage from "./pages/TeamPage";

import Navbar from "./components/Navbar";
import PolicyPage from "./pages/PrivacyPolicy";
import Corporate from "./pages/CorporateBookings";
import Nri from "./pages/Nri";
// import ONDC from "./pages/ONDC";
import About from "./pages/About";
import MobileAdd from "./components/mobileadd";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import React, { useState, useEffect } from "react";
import Footer from "./components/Footer";

import { AppRoutes } from "./routes/routes";
import { clarity } from "./utils/clarity";
import BhandaraKarwaoWrapper from "./pages/bhandarakarwao";

function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // metaTagsDataConverted();
    function handleScroll() {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    if (process.env.NODE_ENV === "production") clarity.init("oaog8fxm67");

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function scrollToTop(e) {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <BrowserRouter>
      {showButton && (
        <button id="button" className="show" onClick={scrollToTop}></button>
      )}

      <Navbar />

      <Routes>
        <Route path={AppRoutes.home} element={<Home />} />
        {/* <Route path={AppRoutes.team} element={<TeamPage />} /> */}
        <Route path={AppRoutes.privacypolicy} element={<PolicyPage />} />
        <Route path={AppRoutes.corporate} element={<Corporate />} />
        <Route path={AppRoutes.nri} element={<Nri />} />
        {/* <Route path={AppRoutes.ondc} element={<ONDC />} /> */}
        <Route path={AppRoutes.about} element={<About />} />
        <Route path={AppRoutes.contact} element={<Contact />} />
        <Route path={AppRoutes.blog} element={<Blog />} />
        <Route
          path={AppRoutes.bhandarakarwao}
          element={<BhandaraKarwaoWrapper />}
        />
      </Routes>
      <MobileAdd />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
