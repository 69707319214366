import React from "react";
import { Zoom } from "react-awesome-reveal";
import "../style/Blog.css";
import { SeoComponentsBuilder } from "../components/seoComponent/SeoComponentsBuilder";
import { metaTagsData } from "../routes/metaTagsData";
import { AppRoutes } from "../routes/routes";

const Blog = () => {
  return (
    <>
      <SeoComponentsBuilder metaTags={metaTagsData[AppRoutes.blog]} />

      <Zoom triggerOnce={true}>
        {/* HEADER */}
        <section className="ban_sec">
          <br></br>
          <br></br>
          <div className="ban_img">
            <img src="/images/blog-banner.jpg" alt="banner" border="0" />
            <div className="ban_text"></div>
          </div>
        </section>
        <div className="container7">
          <div className="card">
            <div className="card__header">
              <img
                src="/images/hh.webp"
                alt="card__image"
                className="card__image"
                width="600"
              />
            </div>
            <div className="card__body">
              <h4>Bhandara App: Bridging Communities through Free Food</h4>
              <p>
                In the fast-paced world of startups and technology, stories that
                stand out are those driven by a vision to make a positive impact
                on society. The tale of Bhandara App is one such narrative – an
                inspiring journey of bridging communities through the simple yet
                powerful act of sharing free food.
              </p>
            </div>
            <div className="card__footer text-center">
              <button className="btn btn_all">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://startupstorymedia.com/stories-2023-10-bhandara-app-free-food-startup-story/"
                >
                  Read More<i className="fa-solid fa-caret-up ms-3"></i>
                </a>
              </button>
            </div>
          </div>

          <div className="card">
            <div className="card__header">
              <img
                src="/images/poori sabji 2.jpg"
                alt="card__image"
                className="card__image"
                width="600"
              />
            </div>
            <div className="card__body">
              <h4>
                घर की दाल रोटी छोड़ो... भंडारे की आलू-पूरी तोड़ो! बस करना होगा
                ये ऐप डाउनलोड
              </h4>
              <p>
                नई दिल्ली: भंडारे को लेकर कई लोगों में गजब का क्रेज देखने को
                मिलता है. कई लोग तो कहीं जा रहे होते हैं और रास्ते में भंडारा
                दिख जाए तो गाड़ी रोकर भंडारा खाने लग जाते हैं. भले ही जहां वह जा
                रहे हैं वहां जाने में देर हो जाए. कई लोग तो भंडारे के बारे में
                जानते ही उस ओर भागते हैं, जहां भंडारा हो रहा हो या होने वाला हो.
                बैचलर लोगों के लिए तो भंडारा वरदान के समान होता है.
              </p>
            </div>
            <div className="card__footer text-center">
              <button className="btn btn_all">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://hindi.news18.com/news/nation/bhandara-app-live-location-free-food-for-everyone-startup-story-know-success-story-of-himanshu-jadon-7897694.html"
                >
                  Read More<i className="fa-solid fa-caret-up ms-3"></i>
                </a>
              </button>
            </div>
          </div>

          <div className="card">
            <div className="card__header">
              <img
                src="/images/poori sabji.jpg"
                alt="card__image"
                className="card__image"
                width="600"
              />
            </div>
            <div className="card__body">
              <h4>
                भंडारे वाली पूड़ी-सब्जी खाने का मन है, नजदीक और दूर के भंडारे का
                पता यूं चलेगा
              </h4>
              <p>
                बात हो रही है गूगल प्ले पर उपलब्ध Bhandara ऐप की. जो आपको लगे कि
                कोई बतोलेबाजी हो रही तो भियो ऐसा नहीं है. एकदम फुल्ली फंक्शनल ऐप
                है. ऐप की टैग लाइन है 'ab bhukhe nahi rahoge'. वाकई में ऐसा है
                क्या.
              </p>
            </div>
            <div className="card__footer text-center">
              <button className="btn btn_all">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.thelallantop.com/technology/post/bhandara-app-google-play-features-specifications-availability-google-maps"
                >
                  Read More<i className="fa-solid fa-caret-up ms-3"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="container7">
          <div className="card">
            <div className="card__header">
              <img
                src="/images/00.jpg"
                alt="card__image"
                className="card__image"
                width="600"
              />
            </div>
            <div className="card__body">
              <h4>
                Bhandara App Revolutionizing Social Food Sharing for Community
                Good
              </h4>
              <p>
                In the dynamic landscape of startups and technological
                innovation, stories that resonate are those driven by a vision
                to create a positive societal impact. The narrative of Bhandara
                App stands out as an inspiring journey that bridges communities
                through the simple yet profound act of sharing free food.
              </p>
            </div>
            <div className="card__footer text-center">
              <button className="btn btn_all">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://startuptimes.net/bhandara-app-revolutionizing-social-food-sharing-for-community-good"
                >
                  Read More<i className="fa-solid fa-caret-up ms-3"></i>
                </a>
              </button>
            </div>
          </div>
          <div className="card">
            <div className="card__header">
              <img
                src="/images/poori sabji.jpg"
                alt="card__image"
                className="card__image"
                width="600"
              />
            </div>
            <div className="card__body">
              <h4>
                कब, कहां चल रहा है भंडारा? ये खुद Bhandara ही बताएगा, जानें-
                कैसे आलू-पूरी के दीवानों की आई मौज
              </h4>
              <p>
                Bhandara App: आलू पूरी के दीवानों को अब दरबदर घूमने की जरूरत
                नहीं, क्योंकि अब आपका फोन ही बता देगा कि कहां भंडारा चल रहा है.
                ऐसा भी कह सकते हैं कि खुद भंडारा बताएगा भंडारे का रास्ता. जी
                हां, एक ऐप है Bhandara, जिसका मकसद ही लोगों को भंडारे की जानकारी
                देना है. इसमें यह सुविधा भी है कि जैसे अगर आप भंडारा करा रहे हैं
                और आपको लोगों को सूचित करना है तो वो भी इस ऐप के जरिए किया जा
                सकता है.
              </p>
            </div>
            <div className="card__footer text-center">
              <button className="btn btn_all">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://zeenews.india.com/hindi/zee-hindustan/utility-news/when-and-where-is-aloo-puri-available-for-free-bhandara-app-will-tell-you-the-way/2052667"
                >
                  Read More<i className="fa-solid fa-caret-up ms-3"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </Zoom>
      {/* </div> */}
    </>
  );
};

export default Blog;
